import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import { StudentLoanSavingsResult } from 'enums/StudentLoanForgivenessFlowResults';
import { FlowComponentType } from 'routes/FlowRouter';
import FormNavigation from 'components/FormNavigation';
import { getRepaymentLabel } from 'components/Card/StudentLoanForgiveness/commonCalculations';
import { addMonths, formatMonthYear } from 'utils/dateUtils';
import AreYouSureModal from 'components/Card/StudentLoanForgiveness/Modal/AreYouSureModal';

import styles from './StudentLoanSavings.module.scss';

const StudentLoanSavings = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const { monthSavings, monthsToStandardRepayment, monthsToPublicServiceRepayment } = useSelector(getStudentLoanData);
  const label = getRepaymentLabel(monthSavings ?? 0, monthsToPublicServiceRepayment ?? 0);

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title={
          <>
            Act Now! <br /> You could be out of debt
          </>
        }
      >
        <p className={styles.yearsToSaveLabel}>{label}</p>

        <p className={clsx(styles.label, styles.spaced)}>
          You likely qualify for federal student loan forgiveness. Unlock a one-time exemption on your student loans.
          <br />
          <strong>This program is ending soon.</strong>
        </p>

        {monthsToStandardRepayment !== undefined &&
          monthsToStandardRepayment > 0 &&
          monthsToPublicServiceRepayment !== undefined &&
          monthsToPublicServiceRepayment > 0 && (
            <>
              <p className={styles.label}>Your estimated payoff:</p>
              <div className={styles.compareWrapper}>
                <div className={styles.compareTable}>
                  <div className={styles.tableHeader}>
                    <p>Current Path</p>
                  </div>
                  <div className={styles.tableBody}>
                    <strong>{formatMonthYear(addMonths(new Date(), monthsToStandardRepayment))}</strong>
                    <p>debt free</p>
                  </div>
                </div>
                <div className={clsx(styles.compareTable, styles.purple)}>
                  <div className={styles.tableHeader}>
                    <p>With Plannery</p>
                  </div>
                  <div className={styles.tableBody}>
                    <strong className={styles.purple}>
                      {formatMonthYear(addMonths(new Date(), monthsToPublicServiceRepayment))}
                    </strong>
                    <p>debt free</p>
                  </div>
                </div>
              </div>
            </>
          )}

        <Button className={styles.button} onClick={() => handleNext(StudentLoanSavingsResult.Next)}>
          Next
        </Button>

        <Button type={ButtonType.Inverted} onClick={() => setModalOpen(true)}>
          Not Now
        </Button>

        {modalOpen && (
          <AreYouSureModal
            onClose={() => setModalOpen(false)}
            onNext={() => handleNext(StudentLoanSavingsResult.Next)}
            onNotNow={() => handleNext(StudentLoanSavingsResult.NotNow)}
          />
        )}
      </FormContainer>
    </>
  );
};

export default StudentLoanSavings;
