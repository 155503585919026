import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import { formatDate } from 'utils/dateUtils';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { getCardData } from 'selectors/getCardData';
import { getStudentLoanAssistanceData } from 'thunks';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const NonProfitHireDate = ({ navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { paymentStartDate } = useSelector(getStudentLoanData);
  const { applicationId } = useSelector(getCardData);
  const [hireDate, setHireDate] = useState<string | undefined>(paymentStartDate);
  const [isLoading, setIsLoading] = useState(false);
  const handleNext = async () => {
    setIsLoading(true);
    analytics.track('Student Loan Non-Profit Hire Date Selected', { hireDate });
    await dispatchWithUnwrap(
      getStudentLoanAssistanceData({ applicationId: applicationId!, startDate: new Date(hireDate!).toISOString() }),
    );
    setIsLoading(false);
    onNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <QuestionsWrapper>
        <>
          <DatePicker
            label="When did you start working in nonprofits?"
            selected={hireDate ? new Date(hireDate) : undefined}
            className={styles.datePicker}
            showMonthYearPicker
            placeHolder="Month YYYY"
            onChange={(date) => setHireDate(formatDate(date))}
          />
          <p className={styles.hint}>An estimate is fine here. We’ll verify this later.</p>

          <Button disabled={Boolean(!hireDate)} onClick={handleNext} isLoading={isLoading} className={styles.button}>
            Next
          </Button>
        </>
      </QuestionsWrapper>
    </>
  );
};

export default NonProfitHireDate;
